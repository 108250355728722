import './style/index.scss'
import './style/banner.scss'
// import './style/header.scss'
// import './style/footer.scss'
 


(async () => {
  const { Swiper, Autoplay, Navigation, Pagination } = await import(/* webpackChunkName: "swiper" */ 'swiper')
  Swiper.use([Navigation, Pagination, Autoplay])
  // const animation = new Animat($('.sow-suit img'))
  // console.log(animation);
  // animation.start({paths,type: 'enter'})
  var mySwiper = new Swiper('.banner-box .swiper-container', {
    direction: 'horizontal', // 垂直切换选项
    loop: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + '</span>';
      },
    },
  });

})()

var video = document.getElementsByClassName('story-video-active')

$('.story-swiper-container').hover(function () {
  $('.control-arrow').addClass('control-arrow-show')
  //$('.story-swiper-slide-active img').css('opacity','0')
}, function () {
  $('.control-arrow').removeClass('control-arrow-show')
  //$('.story-swiper-slide-active img').css('opacity','1')
})

$('.control-btn-right').click(function () {
  $('.story-swiper-wrapper li').removeClass('story-swiper-slide-active')
  rightArrowClick()
  $('.story-swiper-wrapper li:eq(0)').addClass('story-swiper-slide-active')
  resetSatrt()
})
$('.control-btn-left').click(function () {
  $('.story-swiper-wrapper li').removeClass('story-swiper-slide-active')
  leftArrowClick()
  $('.story-swiper-wrapper li:eq(0)').addClass('story-swiper-slide-active')
  resetSatrt()


})
function resetSatrt() {
  for (var i = 0; i < video.length; i++) {
    video[i].pause()
  }
  video[0].play()
}
function leftArrowClick() {
  $('.story-swiper-wrapper li:eq(2)').prependTo('.story-swiper-wrapper')
}
function rightArrowClick() {
  $('.story-swiper-wrapper').append($('.story-swiper-wrapper li:eq(0)'))
}

